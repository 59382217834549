import React from 'react';
import { graphql, Link, withPrefix } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/en';
import ProfessionalismImage from '../images/professionalism.jpg';
import SolutionsImage from '../images/innovative-solutions.jpg';

const Home = (props) => {
  const features = props.data.allFeaturesJson.edges;

  return (
    <Layout bodyClass="page-home" location={props.location}>
      <SEO title="Home"/>
      <Helmet>
        <meta
          name="Massam Corporate Consulting"
          content="Small Business Theme. Multiple content types using Markdown and JSON sources. Responsive design and SCSS. This is a beautiful and artfully designed starting theme."
        />
      </Helmet>
      <div className="intro">
        <div className="intro-container">
          <div className="intro-row">
            <div className="intro-content col-12 col-md-6">
              <h1>A Solution for Your Business</h1>
              <p>
                Massam Corporate Consulting provides a solution for your business with our global and broad experience
                in various industries. We understand the challenges across various sectors at this difficult time. More
                than assisting in your books and records, we also specialize in business advisory, digital
                transformation, management accounting and analysis.
              </p>
              <a href="/about" className="button">
                About Us
              </a>
            </div>
            <div className="intro-hero col-md-6"/>
          </div>
        </div>
      </div>

      <div className="p-4 intro-about">
        <h3 className="text-center">
          Massam Corporate Consulting
        </h3>
        <h4 className="text-center">strives to deliver above your expectation.</h4>
      </div>

      <div className="container pt-6 pb-md-6 professionalism">
        <div className="row">
          <div className="col-12 col-md-6 content pl-md-4 text-center text-md-left order-2 order-md-1">
            <h4 className="title-3 text-dark">With our Professionalism</h4>
            <p>
              and a wide network of expertise, we are a problem solver to your business. Our partnership will enable
              you to focus on where your strength is to flourish the continuous success of
              your business.
            </p>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2">
            <img src={ProfessionalismImage} alt=""/>
          </div>
        </div>
      </div>

      <div className="container pt-2 pb-md-2 professionalism">
        <div className="row">
          <div className="col-12 col-md-6">
            <img src={SolutionsImage} alt=""/>
          </div>
          <div className="col-12 col-md-6 content pr-md-4 text-center text-md-left">
            <h4 className="title-3 text-dark">Innovative Solutions</h4>
            <p>
              As young and vibrant business consultants, we produce tailor-made and innovative solutions for your
              needs. A customized solution is always built from our thorough understanding of your business.
            </p>
          </div>
        </div>
      </div>


      <div className="container pt-8 pt-md-2">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-3">Our Approach</h2>
          </div>
          <div className="col-12 col-md-6 mb-1">
            <div className="card service service-teaser">
              <div className="card-content">
                <h2>Practical Question</h2>
                <p>
                  We start by asking better questions: questions that challenge, inspire and unlock new answers to
                  some of today’s most pressing challenges. We believe in right questions bring out right
                  solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-1">
            <div className="card service service-teaser">
              <div className="card-content">
                <h2>
                  Effective Interaction
                </h2>
                <p>
                  We believe that every advisory opportunity and every interaction with a client or colleague help our
                  clients operate, manage risk, foster growth and inspire confidence in a more efficient manner.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/services">
              View All Services
            </Link>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">Our Alliances</h2>
          </div>
          {features.map(edge => (
            <div key={edge.node.id} className="col-12 col-lg-4 mb-2">
              <div className="feature">
                {edge.node.image && (
                  <div className="feature-image">
                    <img src={withPrefix(edge.node.image)} alt=""/>
                  </div>
                )}
                <h2 className="feature-title">{edge.node.title}</h2>
                <div className="feature-content">{edge.node.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
    query {
        allFeaturesJson {
            edges {
                node {
                    id
                    title
                    description
                    image
                }
            }
        }
    }
`;

export default Home;
